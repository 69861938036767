import { createStore } from 'vuex'

declare let SessionStorage:any;
const USER = 'USER';
const EXAM = 'EXAM';
const STUDENT = 'STUDENT';
const QUESTION = 'QUESTION'

const store = createStore({
  state: {
    user: SessionStorage.get(USER) || {},
    exam: SessionStorage.get(EXAM) || {},
    student: SessionStorage.get(STUDENT) || {},
    question: SessionStorage.get(QUESTION) || [],
  },
  mutations: {
    setUser ( state, user ) {
      state.user = user
      SessionStorage.set(USER, user)
    },
    setExam (state, exam) {
      state.exam = exam
      SessionStorage.set(EXAM, exam)
    },
    setStudent (state, student) {
      state.student = student
      SessionStorage.set(STUDENT, student)
    },
    setQuestion (state, question) {
      state.question = question
      SessionStorage.set(QUESTION, question)
    }
  },
  actions: {
  },
  modules: {
  }
})
export default store;
