import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Tool } from './util/tool'
import { ElMessage } from 'element-plus'

axios.defaults.baseURL = process.env.VUE_APP_SERVER
/*
* axios 拦截器
* */
axios.interceptors.request.use(config => {
    // console.log('请求参数：', config)
    const token = store.state.user.token
    if ( Tool.isNotEmpty(token) ) {
        config.headers.Authorization = 'Bearer ' + token
        // console.log('请求加入token：', token)
    }
    return config
}, error => {
    return Promise.reject(error)
})
axios.interceptors.response.use((res:any) => {
    // console.log('返回结果：', res)
    const { data } = res
    if ( data.code === 401 ) {
        ElMessage.error('登录失效，请重新登录！')
        store.commit('setUser', null)
        router.push({
            name: 'Login'
        })
    } else {
        return res
    }
}, error => {
    return Promise.reject(error)
})

const app = createApp(App);
app.use(store).use(router).use(ElementPlus).mount('#app')

// console.log('环境：', process.env.NODE_ENV)
// console.log('服务端：', process.env.VUE_APP_SERVER)
