import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";
import {Tool} from "@/util/tool";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
    meta: {
      isLogin: true
    }
  },
  {
    path: '/Examre/:enrollId/:planId/:planstudentId',
    name: 'Examre',
    component: () => import('../views/Examre/Index.vue'),
    meta: {
      isLogin: true
    }
  },
  {
    path: '/Examination/:enrollId/:planId',
    name: 'Examination',
    component: () => import('../views/Examination/Index.vue'),
    meta: {
      isLogin: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.matched.some(item => {
    // console.log(item, '是否需要登录校验:', item.meta.isLogin)
    return item.meta.isLogin
  }) ) {
    const loginUser = store.state.user
    if ( Tool.isEmpty(loginUser) ) {
      // console.log('用户未登录')
      next('/login')
    } else {
      next()
    }
  } else {
    const loginUser = store.state.user
    if ( !Tool.isEmpty(loginUser) ) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
